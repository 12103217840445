@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Newsreader:opsz,wght@6..72,700&display=swap');

@import '@clubmed/trident-ui/style.css';

.scroll-hidden {
  margin-bottom: -40px;
  padding-bottom: 40px;
}

.force-white {
  filter: brightness(0) saturate(100%) invert(100%);
}
